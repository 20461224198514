<!--
 * @Author: wanghui@ykccn.com
 * @Date: 2023-02-13
 * @Description: 营销管理/平台营销工具/卡券价格活动/新增或者编辑(扫码领券)
-->
<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm">
    <ykc-form-item label="活动名称" prop="activityName">
      <ykc-input
        v-model="ruleForm.activityName"
        maxlength="50"
        placeholder="请输入活动名称"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="活动时间" prop="activityTime">
      <ykc-date-picker limitDate="limitBefore" v-model="ruleForm.activityTime"></ykc-date-picker>
    </ykc-form-item>
    <ykc-form-item label="活动标题" prop="activityTitle">
      <ykc-input
        v-model="ruleForm.activityTitle"
        maxlength="50"
        placeholder="请输入活动标题"></ykc-input>
    </ykc-form-item>
    <ykc-form-item
      class="file-error img-error"
      label="活动图片"
      bottomTip="温馨提示：请上传1M以内尺寸为750px*320px的图片，用于客户端展示"
      prop="activityImage">
      <ykc-upload
        :size="1"
        ref="ykcupload"
        :file="setFile"
        @handleRemove="handleRemove"
        :checkInfo="{ typeMess: '文件格式错误，请重新导入', sizeMess: '文件大小不超过' }"
        @handleChange="handleUpload"></ykc-upload>
    </ykc-form-item>
    <ykc-form-item label="活动规则" prop="activityRule">
      <ykc-input
        type="textarea"
        v-model="ruleForm.activityRule"
        :maxlength="500"
        placeholder="请输入活动规则"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="卡券设置" class="required">
      <ykc-combination-item
        required
        :basicData="basicData"
        :selectData="coupons"
        :data="setData"
        :maxlength="20"
        addText="添加优惠券/卡券"
        ref="combination"></ykc-combination-item>
    </ykc-form-item>

    <div class="limited-wrap">
      <ykc-form-item label="限制次数" prop="limitCount">
        <ykc-input
          v-model="ruleForm.limitCount"
          :maxlength="8"
          placeholder="请输入次数"></ykc-input>
      </ykc-form-item>
      <ykc-dropdown
        placeholder="请选择"
        :clearable="false"
        :data="LIMITED_TYPE"
        v-model="ruleForm.limitType"></ykc-dropdown>
    </div>
    <ykc-form-item label="用户维度" prop="userScope">
      <ykc-radio
        :data="[
          { id: '1', name: '按客户发送' },
          { id: '2', name: '按用户分组' },
          { id: '3', name: '全部用户' },
        ]"
        v-model="ruleForm.userScope" />
    </ykc-form-item>
    <ykc-form-item prop="userList" label="适用用户范围设置" v-if="ruleForm.userScope === '1'">
      <ykc-tree
        ref="treeUser"
        :data="usersData"
        :props="treeProps"
        @check-change="handleUsersTree" />
    </ykc-form-item>
    <ykc-form-item prop="userGroupList" label="适用用户范围设置" v-if="ruleForm.userScope === '2'">
      <ykc-tree
        ref="treeUserGroup"
        :data="userGroupData"
        :props="treeProps"
        @check-change="handleUsersGroupTree" />
    </ykc-form-item>
  </ykc-form>
</template>
<script>
  import regexpObj from '@/utils/regexp';
  import { couponActivity, marketManage, treeUtils, uploadUtils } from '@/service/apis';

  export default {
    props: {
      editModel: {
        type: String,
        default: 'add', // add edit copy
      },
      activityId: {
        type: String,
      },
    },
    data() {
      return {
        LIMITED_TYPE: [
          {
            id: '1',
            name: '次/人/天',
          },
          {
            id: '2',
            name: '次/人/活动周期',
          },
        ],
        selectData: [],
        basicData: [
          {
            comName: 'YkcDropdown',
            key: 'couponId',
            remote: true,
            value: '',
            placeholder: '请选择优惠券/抵用卡',
            rules: { required: true, message: '请选择优惠券/抵用卡', trigger: 'blur' },
            prepend: '卡券',
            only: true,
            data: [],
          },
          {
            comName: 'YkcInput',
            value: '',
            key: 'issueCount',
            maxlength: 8,
            prepend: '数量',
            append: '张/人',
            placeholder: '请输入卡券数量',
            rules: [
              { required: true, message: '请输入卡券数量', trigger: 'blur' },
              {
                pattern: regexpObj.regexp.input.integerNumberType.regexp,
                message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                  '卡券数量',
                  99999999
                ),
                trigger: 'blur',
              },
              { validator: this.checkCouponNumber, trigger: 'blur' },
            ],
          },
        ],
        ruleForm: {
          activityName: '',
          activityTime: [],
          activityTitle: '', // 标题
          activityImage: '', // 图片
          activityRule: '', // 规则
          limitType: '1', // 限制类型（1:次/人/天，2:次/人/活动周期）
          limitCount: '', // 限制次数
          userScope: '1', // 用户范围(1:按客户发放，2:按用户分组）
          userList: [],
          userGroupList: [],
        },
        rules: {
          activityName: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('活动名称'),
              trigger: 'blur',
            },
            { validator: this.onlyCheckName, trigger: 'blur' },
          ],
          activityTime: [{ required: true, trigger: 'change', message: '请选择活动时间' }],
          userScope: [{ required: true, trigger: 'change', message: '请选择用户维度' }],
          activityTitle: [
            { required: true, message: '请输入活动标题', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('活动标题'),
              trigger: 'blur',
            },
          ],
          limitCount: [
            { required: true, trigger: 'blur', message: '请输入设置次数' },
            {
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                '设置次数',
                99999999
              ),
              trigger: 'blur',
            },
          ],
          activityRule: [
            // 活动规则
            { required: false, trigger: 'blur', message: '请输入活动规则' },
            {
              pattern: regexpObj.regexp.input.textAreaType.regexp,
              message: regexpObj.regexp.input.textAreaType.errorTips.error('活动规则'),
              trigger: 'blur',
            },
          ],
          activityImage: [{ required: false, message: '请上传文件', trigger: 'change' }],
          userList: [{ required: true, message: '请选择用户', trigger: 'change' }],
          userGroupList: [{ required: true, message: '请选择用户分组', trigger: 'change' }],
        },
        usersData: [],
        userGroupData: [],
        treeProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        coupons: [],
        setData: [],
        setFile: [],
      };
    },
    created() {
      this.getData();
    },
    model: {
      prop: 'drawerVisible',
      event: 'change',
    },
    methods: {
      getData() {
        const requests = [];
        const requestCoupons = marketManage.activityList({
          // cardCouponType://卡券类型（1:抵用卡，2:优惠券）
          useType: '1', // 使用类型（1:普通，2:会员）
          undertakerType: '1', // 承担方类型（1:平台，2:商户）
          // undertakerId://承担商户id
          // batchNumber;//批次号
        });
        const userTreeRequest = treeUtils.operatorUsers({});
        const userTreeGroupRequest = treeUtils.operatorUserGroup({});
        requests.push(requestCoupons);
        requests.push(userTreeRequest);
        requests.push(userTreeGroupRequest);

        if (this.activityId && (this.editModel === 'edit' || this.editModel === 'copy')) {
          const requestDetail = couponActivity.scanActivityDetail({
            activityId: this.activityId,
          });
          requests.push(requestDetail);
        }
        Promise.all(requests).then(([resCouponRes, usersDataRes, userGroupRes, resDetail]) => {
          if (resDetail) {
            this.detail = resDetail || {};
          }
          if (resCouponRes) {
            this.coupons = [];
            if (
              this.activityId &&
              (this.detail.activityStatus === '1' || this.detail.activityStatus === '2')
            ) {
              // 只有未开始进行中，进行复制卡券
              this.detail.rewardList.forEach(item => {
                this.coupons.push({
                  cardCouponId: item.rewardId,
                  id: item.rewardId,
                  name: item.rewardName,
                  cardCouponType: item.rewardType,
                  leftCount: item.rewardCount,
                });
              });
            }
            const coupons = resCouponRes.map(item => {
              const { cardCouponId, cardCouponName, remainderNum } = item;
              item.id = cardCouponId;
              item.name = cardCouponName;
              item.leftCount = remainderNum;
              return item;
            });
            this.coupons.push(...coupons);
            // 如果是选中状态
            if (this.activityId) {
              this.$nextTick(() => {
                this.setData = this.formatSetData();
              });
            }
          }
          this.formatUserTreeData(usersDataRes || []);
          this.formatUserGroupTreeData(userGroupRes || []);
          if (this.activityId) {
            this.fillInitData();
          }
        });
      },
      formatUserTreeData(data) {
        if (data) {
          this.usersData = [];
          if (data) {
            let id = 1000000;
            // eslint-disable-next-line no-restricted-syntax
            for (const [key, users] of Object.entries(data)) {
              console.log(key, users);
              const user = {
                id: id++,
                name: key,
                subNodes: [],
                disabled: false,
              };
              users.forEach(item => {
                user.subNodes.push({
                  id: item.id,
                  name: item.name,
                });
              });
              this.usersData.push(user);
            }
          }
        }
      },
      formatUserGroupTreeData(data) {
        this.userGroupData = [];
        if (data?.length > 0) {
          data.forEach(item => {
            const userGroup = {
              id: item.id,
              name: item.groupName || '未知',
              disabled: false,
            };
            this.userGroupData.push(userGroup);
          });
        }
      },
      /**
       * 填充初始化数据
       * @param selectedUsers
       */
      fillInitData() {
        if (this.editModel === 'edit') {
          this.ruleForm.activityName = this.detail.activityName;
          this.ruleForm.activityTime = [];
          if (this.detail.activityBeginTime) {
            // 这里有问题 编辑没有返回时间
            this.ruleForm.activityTime.push(this.detail.activityBeginTime);
          }
          if (this.detail.activityEndTime) {
            this.ruleForm.activityTime.push(this.detail.activityEndTime);
          }
        }
        this.ruleForm.activityTitle = this.detail.activityTitle || '';
        this.ruleForm.activityImage = this.detail.activityImage || '';
        this.$refs.ykcupload.fileList = [{ url: this.ruleForm.activityImage }];
        this.ruleForm.activityRule = this.detail.activityRule || '';
        // 限领次数
        this.ruleForm.limitType = this.detail.limitType;
        this.ruleForm.limitCount = this.detail.limitCount;
        // 用户维度
        this.ruleForm.userScope = this.detail.userScope;
        if (this.ruleForm.userScope === '1') {
          this.ruleForm.userList = this.fillTreeSelectedIds(this.detail.userScopeList);
          this.selectTreeNodes(this.$refs.treeUser, this.ruleForm.userList);
        } else if (this.ruleForm.userScope === '2') {
          this.ruleForm.userGroupList = this.fillTreeSelectedIds(this.detail.userScopeList);
          this.selectTreeNodes(this.$refs.treeUserGroup, this.ruleForm.userList);
        }
      },
      /**
       * 获取文件
       */
      handleUpload(file, fileList, isCheck) {
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          console.log('param', param);
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadImg(formData).then(res => {
            this.ruleForm.activityImage = res;
            this.$refs.ruleForm.validateField('ykcupload');
          });
        }
      },
      // 登录logo删除
      handleRemove() {
        this.ruleForm.activityImage = undefined;
      },
      /**
       * 选择树形控件
       * @param tree
       * @param selectedNodes
       */
      selectTreeNodes(tree, selectedNodes) {
        this.$nextTick(() => {
          tree.setCheckedKeys(selectedNodes, true);
        });
      },
      formatSetData() {
        const selectedCoupons = [];
        this.detail.rewardList.forEach(item => {
          selectedCoupons.push({
            couponId: item.rewardId,
            issueCount: item.rewardCount,
          });
        });
        return selectedCoupons;
      },
      fillTreeSelectedIds(records) {
        const selectedIds = [];
        records.forEach(item => {
          selectedIds.push(item.dataId);
        });
        return selectedIds;
      },
      handleUsersTree() {
        this.ruleForm.userList = this.$refs.treeUser.getCheckedKeys(true) || [];
        // console.log('this.ruleForm.stationList', checkedKeys, this.ruleForm.stationList);
      },
      handleUsersGroupTree() {
        this.ruleForm.userGroupList = this.$refs.treeUserGroup.getCheckedKeys(true) || [];
      },
      /**
       * 提交
       * @returns {Promise<unknown>}
       */
      submitForm() {
        return new Promise((resolve, reject) => {
          const couponValidForm = this.$refs.combination.getResult();
          const dataValidForm = this.validateAllForm();
          Promise.all([couponValidForm, dataValidForm]).then(([couponResult]) => {
            // 新增/复制/编辑
            if (couponResult.validState) {
              const submitData = this.formData();
              submitData.rewardList = this.formatCouponData(couponResult.data || []);
              couponActivity.scanActivitySave(submitData).then(res => {
                console.log(res);
                this.$message({
                  message: '保存成功',
                  type: 'success',
                });
                resolve();
              });
            } else {
              reject();
            }
          });
        });
      },
      /**
       * 校验表单
       * @returns {Promise<unknown>}
       */
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(err => {
              console.log(err);
              reject();
            });
        });
      },
      formatCouponData(selectedCoupons) {
        const couponDatas = [];
        if (selectedCoupons.length > 0) {
          selectedCoupons.forEach(selectItem => {
            const selectId = selectItem.couponId;
            for (let i = 0; i < this.coupons.length; i++) {
              const originCoupon = this.coupons[i];
              if (originCoupon.cardCouponId === selectId) {
                couponDatas.push({
                  rewardType: originCoupon.cardCouponType,
                  rewardId: selectId,
                  rewardCount: selectItem.issueCount || '',
                });
                break;
              }
            }
          });
        }
        return couponDatas;
      },
      formData() {
        const startTime =
          this.ruleForm.activityTime[0].indexOf('00:00:00') > -1
            ? this.ruleForm.activityTime[0]
            : `${this.ruleForm.activityTime[0]} 00:00:00`;
        const endTime =
          this.ruleForm.activityTime[1].indexOf('00:00:00') > -1
            ? this.ruleForm.activityTime[1]
            : `${this.ruleForm.activityTime[1]} 00:00:00`;
        const formData = {
          activityType: '5',
          activityInitiator: '1',
          activityInitiatorId: '0',
          activityName: this.ruleForm.activityName,
          activityBeginTime: startTime,
          activityEndTime: endTime,
          activityTitle: this.ruleForm.activityTitle || '',
          activityImage: this.ruleForm.activityImage,
          activityRule: this.ruleForm.activityRule,
          limitType: this.ruleForm.limitType,
          limitCount: this.ruleForm.limitCount,
          userScope: this.ruleForm.userScope,
        };
        if (this.ruleForm.userScope === '1') {
          formData.userScopeList = this.getTreeFormatSelectedDatas(this.$refs.treeUser);
        } else if (this.ruleForm.userScope === '2') {
          formData.userScopeList = this.getTreeFormatSelectedDatas(this.$refs.treeUserGroup);
        }
        // 编辑/copy
        if (this.editModel === 'edit') {
          formData.activityId = this.activityId;
        }
        return formData;
      },
      getTreeFormatSelectedDatas(tree) {
        const selectedDatas = [];
        const items = tree.getCheckedNodes(true, false);
        items.forEach(item => {
          selectedDatas.push({
            dataId: item.id,
            dataName: item.name,
            orgType: item.orgType,
          });
        });
        return selectedDatas;
      },

      /**
       * 输入框数量校验
       */
      checkCouponNumber(rule, value, callback) {
        // 输入的所有数据
        const result = this.$refs.combination.dealResult();
        // 当前行index
        const rowIndex = rule.field.split('.')[1];
        // 选择的优惠券id
        const { couponId = '' } = result[rowIndex];

        if (!couponId) {
          return callback();
        }
        const [coupon] = this.coupons.filter(item => item.id === couponId);

        return Number(value) > coupon.leftCount
          ? callback(`超过剩余数量${coupon.leftCount}张，请重新输入`)
          : callback();
      },
      handleTreeCheckChange(checkedKeys) {
        this.ruleForm.orgList = checkedKeys.length > 0 ? checkedKeys : '';
      },
      /**
       * 活动名称唯一校验
       */
      onlyCheckName(rule, value, callback) {
        if (value) {
          couponActivity
            .checkNameOnly({
              activityName: value,
              activityType: '5',
              activityId: this.editModel === 'edit' ? this.activityId : '',
            })
            .then(res => {
              if (res?.repeatFlag) {
                // 重复
                // 校验未通过
                this.nameValidError = '活动名称已存在'; // res.resultDesc;
                callback(new Error(this.nameValidError));
                // eslint-disable-next-line no-dupe-else-if
              } else if (res?.repeatFlag === false) {
                // 校验通过
                this.nameValidError = '';
                callback();
              }
            })
            .catch(() => {});
        }
      },
      /**
       * 获取文件
       */
      getFile(file, fileList, isCheck) {
        console.log('file, fileList', file, fileList, isCheck);
        // this.ruleForm.activityImage = fileList && file;
        // this.$refs.ruleForm.validateField('activityImage');
        // eslint-disable-next-line no-unused-expressions
        isCheck && this.uploadFile(file);
      },
      /**
       * 上传图片
       */
      uploadFile(file) {
        const formData = new FormData();
        // 处理入参格式
        Object.entries({ file: file.raw }).forEach(([k, v]) => {
          formData.append(k, v);
        });
        // 请求调用
        this.$axios({
          headers: {
            'Content-Type': false,
          },
          url: 'URL_SCAN_UPLOADFILE',
          data: formData,
        })
          .then(res => {
            console.log(res);
            this.ruleForm.activityImage = res.body;
          })
          .catch(() => {
            this.ruleForm.activityImage = '';
            this.$refs.ykcupload.fileList = [];
          });
      },
      /**
       * 获取详情数据
       */
      getDetail(id) {
        this.activityId = id;
        this.$axios({
          url: 'URL_SCAN_ACTIVITY_DETAIL',
          headers: {
            Operation: 'detail',
          },
          data: {
            body: {
              activityId: id,
            },
          },
        })
          .then(data => {
            const detail = data && data.body;
            console.log('data', data);
            this.ruleForm.activityName = detail.activityName;
            this.ruleForm.activityTime = [detail.startTime, detail.endTime];
            this.ruleForm.activityTitle = detail.activityTitle;
            this.ruleForm.activitySubTitle = detail.activitySubTitle;
            this.ruleForm.activityImage = detail.activityImage;
            this.setFile = detail.activityImage ? [{ url: detail.activityImage }] : [];
            this.ruleForm.activityRule = detail.activityRule;
            this.ruleForm.orgList = detail.orgList;
            this.ruleForm.limitCount = detail.limitCount;
            this.ruleForm.limitType = detail.limitType;
            this.setData = detail.couponList || [];
            // this.$refs.tree.setCheckedKeys(details.orgList, true);
            detail.orgList.forEach(key => {
              this.$refs.tree.setChecked(key, true);
            });
          })
          .catch(() => {});
      },
      /**
       * 重置数据
       */
      resetRuleForm() {
        this.activityId = '';
        this.setFile = [];
        this.setData = [];
        this.ruleForm = {
          activityName: '',
          activityTime: [],
          activityTitle: '',
          activitySubTitle: '',
          limitType: '1',
          limitCount: '',
          activityRule: '',
          orgList: '',
          activityImage: '',
        };
      },

      /**
       * 取消操作
       */
      beforeCancel(drawerDone) {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          closeOnClickModal: false,
          desc: '取消后操作将不被保存，确认是否取消？',
          onConfirm: done => {
            // 关闭弹框
            done();
            // 关闭抽屉
            drawerDone();
            this.resetRuleForm();
          },
        });
      },
      /**
       * 保存操作
       */
      async beforeDrawer() {
        console.log('this.activityId', this.activityId);
        const ruleForm = { ...this.ruleForm };
        const { data, validState } = await this.$refs.combination.getResult();
        this.$refs.ruleForm.validate(valid => {
          if (valid && validState) {
            ruleForm.couponList = this.resetdata(data) || [];
            const [startTime, endTime] = ruleForm.activityTime || [];
            ruleForm.startTime = startTime;
            ruleForm.endTime = endTime;
            if (this.activityId) {
              ruleForm.activityId = this.activityId;
            }
            delete ruleForm.activityTime;
            this.$axios({
              url: this.activityId ? 'URL_SCAN_ACTIVITY_EDIT' : 'URL_SCAN_ACTIVITY_ADD',
              headers: {
                Operation: this.activityId ? 'edit' : 'add',
              },
              data: {
                body: ruleForm,
              },
            })
              .then(res => {
                console.log('data', res);
                this.$emit('change', false);
                this.$emit('update-data');
                this.resetRuleForm();
                // 重新获取优惠券(优惠券数据变更了)
                this.getCouponAndFleet(true);
              })
              .catch(() => {});
          }
        });
      },
      handleChangeShow(v) {
        this.$emit('change', v);
        console.log('drawerVisible', this.drawerVisible);
      },
      /**
       * 获取车队/优惠券数据
       */
      getCouponAndFleet(isres) {
        const axiosArr = [
          this.$axios({
            url: 'URL_QUERYCOUPON_FORCTPACTIVITY',
            data: {
              body: {},
            },
          }),
          !isres &&
            this.$axios({
              url: 'URL_QUERY_FLEET_SUBORGTREE',
              //       data: { body: { mainOrgId: sessionGetItem('orgId') } },
            }),
        ];
        this.$axios
          .all(axiosArr)
          .then(
            this.$axios.spread((resCoupon, res) => {
              const couponArr = [];
              resCoupon.body.forEach(item => {
                couponArr.push({
                  id: item.couponId,
                  name: item.couponName,
                  leftCount: item.leftCount,
                });
              });
              this.coupons = couponArr;
              this.iscoupons = true;
              if (res && res.body) {
                this.fleetOrg = res.body || [];
              }
            })
          )
          .catch(() => {
            this.iscoupons = true;
          });
      },
      resetdata(data) {
        this.coupons.forEach(item => {
          data.forEach(o => {
            if (item.id === o.couponId) {
              o.couponName = item.name;
            }
          });
        });
        return data;
      },
    },
  };
</script>
<style lang="scss">
  .drawer-main-body {
    .limited-wrap {
      display: flex;
      .ykc-form-item {
        flex: 1;
      }
      .ykc-dropdown {
        width: 150px;
        margin-left: 10px;
        margin-top: 26px;
      }
    }
  }
</style>
