<template>
  <div class="card-count">
    <DynamicCompose
      :createItem="createItem"
      :value="value"
      @input="$emit('input', $event)"
      :max="20">
      <template #default="{ data, id, handleDelete, deleteDisabled }">
        <ykc-form class="card-count-form" :model="data" :rules="rules">
          <ykc-icon
            name="reduce"
            :class="{ disabled: deleteDisabled }"
            @click="deleteRow({ deleteDisabled, handleDelete, id })"></ykc-icon>
          <ykc-form-item prop="card">
            <ykc-dropdown
              v-model="data.card"
              :remote="true"
              :data="data.data"
              @change="handleCardChange">
              <template #prepend>卡券</template>
            </ykc-dropdown>
          </ykc-form-item>
          <ykc-form-item prop="count" style="margin-left: 8px">
            <ykc-input v-model="data.count" :maxlength="8">
              <template #prepend>数量</template>
              <template #append>张/人</template>
            </ykc-input>
          </ykc-form-item>
        </ykc-form>
      </template>
      <template #add="{ handleAdd, reachMax }">
        <div class="card-count-add" @click="addItem({ handleAdd, reachMax })">
          <ykc-icon name="add"></ykc-icon>
          <span>添加卡券</span>
        </div>
      </template>
    </DynamicCompose>
  </div>
</template>

<script>
  import DynamicCompose from './dynamic-compose.vue';
  import regexpObj from '@/utils/regexp';

  export default {
    components: { DynamicCompose },
    props: {
      value: {
        type: Array,
        required: true,
      },
      totalCardData: {
        type: Array,
        required: true,
      },
    },
    watch: {
      totalCardData: {
        immediate: true,
        deep: true,
        handler: 'handleCardChange',
      },
    },
    computed: {
      rules() {
        return {
          card: [
            {
              validator: (rule, value, callback) => {
                if (!value) {
                  callback(new Error('请选择卡券'));
                }
                callback();
              },
              trigger: 'blur',
            },
          ],
          count: [
            {
              validator: (rule, value, callback) => {
                if (!regexpObj.regexp.input.integerNumberType.regexp.test(value)) {
                  callback(new Error('请输入大于0的张数'));
                }
                callback();
              },
              trigger: 'blur',
            },
          ],
        };
      },
    },
    created() {
      this.handleCardChange();
    },
    methods: {
      deleteRow({ deleteDisabled, handleDelete, id }) {
        if (deleteDisabled) return;
        handleDelete(id);
        // 删除后,重新计算可用的下拉选项
        this.handleCardChange();
      },
      createItem() {
        const item = {
          card: '',
          count: '0',
        };
        this.calculateAvailableCardData(item);
        return item;
      },
      calculateAvailableCardData(current) {
        const used = this.value.filter(item => item !== current).map(item => item.card);
        this.$set(
          current,
          'data',
          this.totalCardData.filter(item => !used.includes(item.id))
        );
      },
      handleCardChange() {
        this.$nextTick(() => {
          this.value.forEach(this.calculateAvailableCardData);
        });
      },
      validate() {
        const ykcFormVms = [...this.$el.querySelectorAll('.ykc-form.card-count-form')].map(
          // eslint-disable-next-line
          node => node.__vue__
        );
        const promisify = item =>
          new Promise(resolve => {
            item.validate(valid => {
              resolve(valid);
            });
          });
        return new Promise(resolve => {
          Promise.all(ykcFormVms.map(promisify)).then(res => {
            resolve(res.every(valid => !!valid));
          });
        });
      },
      addItem({ handleAdd, reachMax }) {
        if (reachMax) {
          // 这里可以给出提示
          return;
        }
        this.validate().then(valid => {
          if (valid) {
            handleAdd();
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  .card-count {
    color: inherit;
    &-form {
      display: flex;
      .ykc-icon-reduce {
        width: 30px;
        text-align: right;
        height: 16px;
        margin-top: 7px;
        margin-right: 8px;
        color: var(--theme-color-primary);
        &.disabled {
          color: #d7d7d7;
          cursor: not-allowed;
        }
      }
    }
    // 处理4.0样式干扰
    & .el-form-item.is-success {
      .ykc-input.el-input {
        .el-input-group__prepend {
          border: 1px solid #d7d7d7 !important;
          border-right: 0 !important;
        }
        .el-input__inner {
          border: 1px solid #d7d7d7 !important;
        }
      }
      .ykc-dropdown-box {
        .el-input-group__prepend {
          border: 1px solid #d7d7d7 !important;
          border-right: 0 !important;
        }
        .el-input__inner {
          border: 1px solid #d7d7d7 !important;
        }
      }
    }
    &-add {
      cursor: pointer;
      font-size: 12px;
      // font-family: PingFangSC-Medium, PingFang SC;
      // font-weight: 500;
      // line-height: 17px;
      color: var(--theme-color-primary);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: fit-content;
      .ykc-icon-add {
        width: 30px;
        text-align: right;
        margin-right: 8px;
        color: var(--theme-color-primary);
      }
    }
  }
</style>
