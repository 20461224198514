<template>
  <div class="money-card-count">
    <DynamicCompose
      :create-item="createItem"
      :value="value"
      @input="$emit('input', $event)"
      :max="5">
      <template #default="{ data, id, handleDelete, deleteDisabled }">
        <ykc-form class="money-card-count-form" :model="data" :rules="data.rules">
          <div class="money">
            <ykc-icon
              name="reduce"
              :class="{ disabled: deleteDisabled }"
              @click="deleteRow({ deleteDisabled, handleDelete, id })"></ykc-icon>
            <ykc-form-item prop="money">
              <ykc-input v-model="data.money" :maxlength="8">
                <template #prepend>充值</template>
                <template #append>元</template>
              </ykc-input>
            </ykc-form-item>
          </div>
          <ykc-form-item prop="cardCount">
            <CardCount
              :value="data.cardCount"
              @input="data.cardCount = $event"
              :totalCardData="totalCardData"></CardCount>
          </ykc-form-item>
        </ykc-form>
      </template>
      <template #add="{ handleAdd, reachMax }">
        <div class="money-card-count-add" @click="addItem({ handleAdd, reachMax })">
          <ykc-icon name="add"></ykc-icon>
          <span>添加充值梯度</span>
        </div>
      </template>
    </DynamicCompose>
  </div>
</template>

<script>
  import { Message } from 'element-ui';
  import DynamicCompose from './dynamic-compose.vue';
  import CardCount from './card-count.vue';
  import regexpObj from '@/utils/regexp';

  const promisify = item =>
    new Promise(resolve => {
      item.validate(valid => {
        resolve(valid);
      });
    });

  export default {
    components: { DynamicCompose, CardCount },
    props: {
      value: {
        type: Array,
        required: true,
      },
      totalCardData: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {};
    },
    watch: {
      value: {
        deep: true,
        immediate: true,
        handler() {
          this.value.forEach(this.generateRules);
        },
      },
    },
    methods: {
      generateRules(data) {
        if (!data.rules) {
          const rules = {
            money: [
              {
                validator: (rule, value, callback) => {
                  if (
                    !regexpObj.regexp.input.amountNumberType.regexp.test(value) ||
                    Number(value) > 99999.99
                  ) {
                    callback(new Error('请输入正确的充值梯度（0~99999.99）'));
                  }
                  callback();
                },
                trigger: 'blur',
              },
              {
                validator: (rule, value, callback) => {
                  const rest = this.value.filter(item => item !== data);
                  if (rest.some(item => String(item.money) === String(value))) {
                    callback(new Error('充值金额不能相同'));
                  } else {
                    callback();
                  }
                },
                trigger: 'blur',
              },
            ],
            cardCount: [
              {
                validator: (rule, value, callback) => {
                  this.validateCardCountInfo().then(valid => {
                    if (valid) {
                      callback();
                    } else {
                      console.log('请查看卡券配置');
                      // callback(new Error('请查看卡券配置'));
                      // 这里用空格,使得其实际视图中不显示
                      callback(new Error(' '));
                    }
                  });
                },
                trigger: 'blur',
              },
            ],
          };
          this.$set(data, 'rules', rules);
        }
      },
      deleteRow({ deleteDisabled, handleDelete, id }) {
        if (deleteDisabled) return;
        handleDelete(id);
      },
      createItem() {
        const item = {
          money: '0',
          cardCount: [
            {
              card: '',
              count: '0',
            },
          ],
        };
        return item;
      },
      validateCardCountInfo() {
        console.log('validateCardCountInfo');
        const cardCountVms = [
          ...this.$el.querySelectorAll('.ykc-form.money-card-count-form .card-count'),
        ].map(
          // eslint-disable-next-line
          node => node.__vue__
        );
        return new Promise(resolve => {
          Promise.all([...cardCountVms].map(item => item.validate())).then(res => {
            console.log('res', res);
            resolve(res.every(valid => !!valid));
          });
        });
      },
      validate() {
        const ykcFormVms = [...this.$el.querySelectorAll('.ykc-form.money-card-count-form')].map(
          // eslint-disable-next-line
          node => node.__vue__
        );
        return new Promise(resolve => {
          Promise.all([...ykcFormVms].map(promisify)).then(res => {
            resolve(res.every(valid => !!valid));
          });
        });
      },
      addItem({ handleAdd, reachMax }) {
        if (reachMax) {
          // 这里可以给出提示
          Message.error('已达最大梯度数');
          return;
        }
        this.validate().then(valid => {
          if (valid) {
            handleAdd();
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  .money-card-count {
    &-form {
      .money {
        display: flex;
        .ykc-icon-reduce {
          width: 30px;
          text-align: right;
          margin-right: 8px;
          margin-top: 4px;
          font-size: 22px;
          color: rgb(247, 180, 0);
          &.disabled {
            color: #d7d7d7;
            cursor: not-allowed;
          }
        }
      }
    }
    &-add {
      width: 30px;
      cursor: pointer;
      font-size: 12px;
      display: flex;
      color: #000000;
      justify-content: flex-start;
      align-items: center;
      width: fit-content;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      .ykc-icon-add {
        width: 30px;
        text-align: right;
        margin-right: 8px;
        font-size: 22px;
        color: rgb(247, 180, 0);
      }
    }
  }
</style>
